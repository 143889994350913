import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import stylelist from "stylelist";
import { copy_slot, delete_slot, move_slot } from "../../actions/Slots";
import { disable_sync, enable_sync } from "../../actions/Sync";
import SyncConstants from "../../constants/SyncConstants";
import useCalendar from "../../hooks/useCalendar";
import useSlots from "../../hooks/useSlots";
import Gravatar from "../../libs/Gravatar";
import { getSlotMetaFromId } from "../../libs/SlotUtils";
import MinutesToTime from "../Expenses/MinutesToTime";
import AddSlot from "./AddSlot";
import ExpenseMask from "./ExpenseMask";
import useAuthentication from "../../hooks/useAuthentication";

const SlotTitle = (props) => {
    const Slots = useSlots();
    const Team = useSelector((state) => state.Team);
    const UserSettings = useSelector((state) => state.User);
    const dispatch = useDispatch();
    const popupRef = useRef();
    const popupRef2 = useRef();
    const Calendar = useCalendar();
    const Auth = useAuthentication();


    // DOM-reference to some divs, used for the "Show more"-button
    const descriptionElementRef = useRef(null);
    const descriptionElementParentRef = useRef(null);

    // showMoreButton: display only if neccesarry (=description is high enough)
    const [showShowMoreButton, setShowShowMoreButton] = useState(false);
    const [showFullDescription, setShowFullDescriptiopn] = useState(false);

    const [showDetails, setShowDetails] = useState(false);

    // can be "disabled", "loading" or "enabled"
    const [slideSlot, setSlideSlot] = useState("disabled");
    const [targetSlideSlotName, setTargetSlideSlotName] = useState("");

    const [icalString, setIcalString] = useState("");

    const [expenseEntryData, setExpenseEntryData] = useState(false);

    const [showExpenseMask, setShowExpenseMask] = useState(false);



    const loadExpenseEntries = () => {
        axios({
            method: "get",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntries/bySlotId",
            params: {
                slotId: props.slotId
            },
            responseType: 'json'
        }).then((response) => {

            setExpenseEntryData(response.data);
            setShowExpenseMask(false);
        }).catch(() => { });
    }

    useEffect(() => {
        loadExpenseEntries();
    }, []);

    useEffect(() => {
        if (descriptionElementRef.current != null && descriptionElementParentRef.current != null) {
            if (descriptionElementRef.current.clientHeight > descriptionElementParentRef.current.clientHeight) {
                setShowShowMoreButton(true);
            }

        }
    });

    const deleteSlotClicked = () => {
        dispatch(delete_slot(props.slotId));
        if (props.deleteMergedSlot) {
            dispatch(delete_slot(props.mergedSlotId));
        }
    }

    const pasteSlotFromKeyboard = (mode = "copy") => {
        if (props.clipboardMode == "copy") {
            dispatch(copy_slot(props.clipboardSlot, props.slotId));
        }

        if (props.clipboardMode == "cut") {
            dispatch(move_slot(props.clipboardSlot, props.slotId));
            props.setClipboard(false);
        }
    }

    const getSlotDateObject = () => {
        return moment(getSlotMetaFromId(props.slotId).slotDate);
    }

    useEffect(() => {
        if (Slots.exists(props.slotId)) {
            setIcalString(Calendar.createIcal(Slots.getBySlotId(props.slotId).content.slotTitle,
                getSlotDateObject(),
                getSlotMetaFromId(props.slotId).slotNumber,
                Team[getSlotMetaFromId(props.slotId).slotUser].email

            ));
        }
    }, [Slots.getBySlotId(props.slotId)])

    return <>

        {/* best case: we have a slot and whot its title with a detailed popup */}
        {(Slots.exists(props.slotId)) &&
            <>
                <Popup
                    on={["click", "right-click"]}
                    mouseEnterDelay="650"
                    modal
                    onOpen={() => { dispatch(disable_sync()) }}
                    onClose={() => { dispatch(enable_sync()) }}
                    ref={popupRef2}
                    className={stylelist([showFullDescription, "scroll-y-overflow"])}
                    disabled={props.controlMode}
                    trigger={
                        <div className={stylelist(
                            "slot-container cursor-pointer p-1",
                            "slot-" + Slots.getBySlotId(props.slotId).content.slotType,
                            [expenseEntryData.length > 0, "has-expenses"],
                            [props.filterWord != "" && Slots.getBySlotId(props.slotId).content.slotTitle.toLowerCase().indexOf(props.filterWord.toLowerCase()) > -1, "filterActive"],
                            [props.filterWord != "" && Slots.getBySlotId(props.slotId).content.slotTitle.toLowerCase().indexOf(props.filterWord.toLowerCase()) < 0, "filterInactive"],
                            [Slots.getBySlotId(props.slotId).content.slotExpensesBooked, "expenses-booked"]
                        )}
                        >

                            <small className="d-flex align-items-center justify-content-center">

                                {((props.controlMode && props.clipboardMode != "none") || (!props.controlMode)) &&

                                    <>
                                        {(props.stealthMode && (Slots.getBySlotId(props.slotId).content.slotTitle.toLowerCase().indexOf(props.filterWord.toLowerCase()) < 0 || props.filterWord.length == 0)) &&
                                            <>
                                                gebucht
                                            </>
                                        }

                                        {((!props.stealthMode) || (props.stealthMode && Slots.getBySlotId(props.slotId).content.slotTitle.toLowerCase().indexOf(props.filterWord.toLowerCase()) > -1 && props.filterWord.length > 0)) &&
                                            <>
                                                {Slots.getBySlotId(props.slotId).content.slotTitle}
                                            </>
                                        }
                                    </>
                                }

                                {(props.controlMode && props.clipboardMode == "none") &&
                                    <>

                                        <div className="d-flex justify-content-between">
                                            <div className="control-mode-icon" onClick={() => { props.setClipboard(props.slotId, "copy"); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                                                    <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                                                </svg>
                                            </div>

                                            <div className="control-mode-icon" onClick={() => { props.setClipboard(props.slotId, "cut"); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-scissors" viewBox="0 0 16 16">
                                                    <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                                                </svg>
                                            </div>


                                            <div className="control-mode-icon" onClick={() => { deleteSlotClicked(); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path
                                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fillRule="evenodd"
                                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </div>

                                        </div>
                                    </>
                                }

                            </small>
                        </div>
                    }
                    position={stylelist(
                        "center",
                        [moment(Slots.getBySlotId(props.slotId).meta.slotDate).weekday() < 2, "left"],
                        [moment(Slots.getBySlotId(props.slotId).meta.slotDate).weekday() == 2, "bottom"],
                        [moment(Slots.getBySlotId(props.slotId).meta.slotDate).weekday() > 2, "right"],
                    )}
                >
                    <div className="px-3 py-3">
                        <div className="row" >
                            <div className="col-12">

                                <div className="row pt-3 ps-2">
                                    <div className="col-12 text-center">
                                        <Gravatar size={120} className={stylelist("avatar", "d-inline-block", "mb-3")} default="mp" email={Team[Slots.getBySlotId(props.slotId).meta.slotUser].email} />

                                    </div>
                                    <div className="col-12 text-center">
                                        <h4 className="w-100 d-inline-block text-left mt-3">{Slots.getBySlotId(props.slotId).content.slotTitle}</h4>
                                        <h6 className="w-100 d-inline-block text-left small text-muted">{Team[Slots.getBySlotId(props.slotId).meta.slotUser].realName}</h6>
                                    </div>
                                </div>



                                {!showExpenseMask &&
                                    <>
                
                                       <ReactMarkdown>{Slots.getBySlotId(props.slotId).content.slotDescription}</ReactMarkdown>
                                        <div className="row mt-1">
                                          
                                            {!showExpenseMask &&
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <div className="mb-4">

                                                            <Link className="badge bg-secondary mt-1 me-1" to={"/slots/edit/" + props.slotId}>Bearbeiten</Link>
                                                            <button
                                                                onClick={() => { popupRef2.current.close(); props.setClipboard(props.slotId, "copy"); }}
                                                                className="badge bg-secondary mt-1 me-1">Kopieren</button>

                                                            <button
                                                                onClick={() => { popupRef2.current.close(); props.setClipboard(props.slotId, "cut"); }}
                                                                className="badge bg-secondary mt-1 me-1">Ausschneiden</button>

                                                            <button
                                                                onClick={() => { setShowExpenseMask(true); }}
                                                                className="badge bg-secondary mt-1 me-1">Aufwände einbuchen</button>

                                                        
                                                            <button onClick={() => { popupRef2.current.close(); deleteSlotClicked(); }} className="badge bg-secondary mt-1 me-1">Löschen</button>
                            

                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-12 mt-2">
                                                <ul className="bullet">
                                                    {Slots.getBySlotId(props.slotId).content.slotType == "simple" &&
                                                        <>
                                                            <li className="small"><b>Einfacher Slot.</b> Einfache Slots besitzen keine Verknüpfung zu GLaDOS, Trello oder anderen externen Systemen.</li>
                                                        </>
                                                    }

                                                    {Slots.getBySlotId(props.slotId).content.slotType == "school" &&
                                                        <>
                                                            <li className="small"><b>Berufsschule.</b> Möglicherweise ist der:die Mitarbeiter:in nach der Berufsschule noch im Unternehmen anzutreffen.</li>
                                                        </>
                                                    }

                                                    <li className="small"><b>iCal.</b> Diesen Termin in deinen Kalender <a href={icalString}>herunterladen</a>.</li>

                                                    {(expenseEntryData === false) &&
                                                        <><li className="small"><b>Gebuchte Aufwände.</b> Wir synchronisieren gerade alle Aufwände (sofern vorhanden).</li></>
                                                    }


                                                    {expenseEntryData.length == 0 &&
                                                        <><li className="small"><b>Keine Aufwände gebucht.</b> Aktuell sind keine Aufwände gebucht.</li></>
                                                    }

                                                </ul>


                                                {expenseEntryData.length > 0 &&
                                                    <>
                                                        <h6 className="mt-4"><b>Aufwände</b></h6>
                                                        <ul className="bullet">
                                                            {expenseEntryData.map(e => {
                                                                return <React.Fragment key={e.id}><li className="small">{e.comment} (<MinutesToTime minutes={e.minutes} />)</li></React.Fragment>
                                                            })}
                                                        </ul>
                                                    </>
                                                }


                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>

                        {showExpenseMask &&
                            <div className="row mt-3">
                                <div className="col-12">
                                    <>
                                        <ExpenseMask slotId={props.slotId} afterSubmit={loadExpenseEntries} />
                                        <button
                                            onClick={() => { setShowExpenseMask(false); }}
                                            className="badge bg-secondary mt-1 me-1">Abbrechen</button>
                                    </>
                                </div>
                            </div>
                        }
                    </div>
                </Popup>
            </>
        }

        {/* also a good case: we have no slot and no other slot in the clipboard - suggest to add a new one */}
        {(!Slots.exists(props.slotId) && !props.clipboardSlot) &&

            <Popup modal ref={popupRef} trigger={<div className="cursor-pointer hover text-muted h-100 py-5"><span className="icon-plus"></span></div>} position="bottom center">
                <div>
                    <AddSlot closeHandler={() => { ref.current.close() }} slotId={props.slotId} />
                </div>
            </Popup>
        }

        {/* not bad: we have no slot but another slot in our clipboard. offer to paste it! */}
        {(props.clipboardSlot && !Slots.exists(props.slotId)) &&
            <div onClick={pasteSlotFromKeyboard} className="cursor-pointer text-muted hover h-100 py-5"><h6><span className="icon-circle-down"></span></h6></div>
        }

    </>
}

export default SlotTitle