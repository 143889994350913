import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { add_section, remove_entry, rename_section, set_current_section } from "../../actions/ExpenseBucket";
import stylelist from "stylelist";
import MinutesToTime from "./MinutesToTime";
import axios from "axios";
import SyncConstants from "../../constants/SyncConstants";
import ConfirmButton from "./ConfirmButton";
import moment from "moment";
import useAuthentication from "../../hooks/useAuthentication";

const ExpenseBucketSection = (props) => {
    const dispatch = useDispatch();
    const ExpenseBucket = useSelector((state) => state.ExpenseBucket);
    const UserSettings = useSelector((state) => state.User);

    const [editName, setEditName] = useState(false);
    const [expandView, setExpandView] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const Auth = useAuthentication();
    const [currentModalTab, setCurrentModalTab] = useState("text");

    const [currentMinuteSum, setCurrentMinuteSum] = useState(0);
    const [textFormat, setTextFormat] = useState("");
    const [csvFormat, setCsvFormat] = useState("");

    const MinutesToTimeString = (minutes) => {
        return Math.floor(minutes / 60).toString().padStart(2, "0") + ":" + (minutes % 60).toString().padStart(2, "0");
    }

    useEffect(() => {
        let newMinuteSum = 0;
        let newString = "";
        let csv = [];
    
        // Initialisiere CSV-Header
        csv.push(["Kommentar", "Person", "Datum", "Dauer"].join("\t"));
    
        // Gruppe Expenses nach Breadcrumb und erfasse Summen pro Breadcrumb
        const groupedByBreadcrumb = {};
        let maxBreadcrumbLength = 0;
    
        ExpenseBucket.sections[props.uuid].expenses.forEach(e => {
            newMinuteSum += parseInt(e.minutes);
    
            // Finde die Länge des längsten Breadcrumb
            if (e.breadcrumb.length > maxBreadcrumbLength) {
                maxBreadcrumbLength = e.breadcrumb.length;
            }
    
            // Gruppe nach Breadcrumb sortieren und Summen erfassen
            if (!groupedByBreadcrumb[e.breadcrumb]) {
                groupedByBreadcrumb[e.breadcrumb] = {
                    expenses: [],
                    totalMinutes: 0
                };
            }
    
            // Füge die Ausgabe zur entsprechenden Breadcrumb-Gruppe hinzu
            groupedByBreadcrumb[e.breadcrumb].expenses.push(e);
            // Erhöhe die Minuten-Summe pro Breadcrumb
            groupedByBreadcrumb[e.breadcrumb].totalMinutes += parseInt(e.minutes);
        });
    
        // Setze aktuelle Minute-Summe
        setCurrentMinuteSum(newMinuteSum);
    
        // Erzeuge eine Trennlinie basierend auf der Länge des längsten Breadcrumbs
        const createSeparator = () => "=".repeat(maxBreadcrumbLength * 0.5); // 20 als Puffer, um Platz für den Text zu haben
    
        // Verarbeite die gruppierten Einträge in CSV und Text
        Object.keys(groupedByBreadcrumb).forEach(breadcrumb => {
            const breadcrumbData = groupedByBreadcrumb[breadcrumb];
    
            // Füge den Breadcrumb als eigene Zeile hinzu (ohne separate Spalte)
            csv.push([breadcrumb, "", "", MinutesToTimeString(breadcrumbData.totalMinutes)].join("\t"));
    
            newString += createSeparator() + "\n";
            newString += "" + breadcrumb + " (Gesamt: " + MinutesToTimeString(breadcrumbData.totalMinutes) + ")\n";
            newString += createSeparator() + "\n\n";
            // Füge alle zugehörigen Einträge hinzu
            breadcrumbData.expenses.forEach(e => {
                csv.push([
                    e.comment.replace('\t', ''),
                    e.person,
                    e.date,
                    MinutesToTimeString(e.minutes),
                ].join("\t"));
    
                newString += "-  " + e.comment.replace('\t', '') + ": " + MinutesToTimeString(e.minutes) + "\n"
                newString += "   Mitarbeiter: " + e.person + "\n";
                newString += "   Datum: " + moment(e.date).format("DD.MM.Y") + "\n\n";
            });
    
            newString += "\n"; // Leerzeile nach jeder Breadcrumb-Gruppe
        });
    
        // CSV in eine formatierbare Zeichenkette umwandeln
        let csvText = csv.join("\n");
        setCsvFormat(csvText);
    
        // Setze das Textformat
        setTextFormat(
            ExpenseBucket.sections[props.uuid].name +
            " (Gesamt: " + MinutesToTimeString(newMinuteSum) + ")\n\n" +
            newString
        );
    }, [ExpenseBucket]);

    const copyTableToClipboard = () => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = csvFormat;
        document.body.appendChild(tempTextArea);
    
        // Text im Textbereich auswählen und kopieren
        tempTextArea.select();
        const copySuccess = document.execCommand("copy");
    
        // Temporären Textbereich entfernen
        document.body.removeChild(tempTextArea);
    
        // Überprüfung, ob das Kopieren erfolgreich war
        if (copySuccess) {
            alert("Das Kopieren war erfolgreich!");
        } else {
            alert("Das Kopieren ist fehlgeschlagen. Bitte versuche es erneut.");
        }
    };

    const markAsAccounted = () => {
        axios({
            method: "post",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "markAsAccounted",
            responseType: 'json',
            data: { entry_ids: ExpenseBucket.sections[props.uuid].expenses }
        }).then((response) => {
            props.forceTreeReload();
            ExpenseBucket.sections[props.uuid].expenses.forEach(entry => {
                dispatch(remove_entry(entry.id))
            });
            alert("Die Aufwände wurden serverseitig als 'Abgerechnet' markiert. Sie werden in Slotify aktualisiert, sobald die Maske aktualisiert wurde.");

        }).catch(response => { });
    }

    return <>
        <li className={stylelist(
            "list-group-item",
            "bg-light"
        )}>

            {showModal && <>
                <div className="overlay d-flex justify-content-center align-items-center">
                    <div className="modal d-block">
                        <div className="modal-dialog" style={{ minWidth: "1024px" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{ExpenseBucket.sections[props.uuid].name} abrechnen</h5>
                                    <button type="button" className="btn-close" onClick={() => { setShowModal(false) }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        <h6>
                                            <span onClick={() => { setCurrentModalTab("text") }} className="badge text-bg-secondary cursor-pointer">Formatierter Text (WeClapp)</span>
                                            &nbsp;
                                            <span onClick={() => { setCurrentModalTab("csv") }} className="badge text-bg-secondary cursor-pointer">Excel</span>
                                            &nbsp;
                                        </h6>
                                    </p>

                                    {currentModalTab == "text" &&
                                        <>
                                            <textarea className="form-control" value={textFormat} style={{ minWidth: "924px", minHeight: "500px" }} />
                                        </>
                                    }

                                    {currentModalTab == "csv" &&
                                        <>
                                            <p>Die gewählten Buchungen werden in die Zwischenablage kopiert und können dann in ein Excel eingefügt werden.</p>
                                            <div className="btn btn-primary" onClick={() => {copyTableToClipboard()}}>Kopieren</div>
                                        </>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <ConfirmButton className="small text-primary cursor-pointer" labelConfirm="Zum Server senden" label="Als 'abgerechnet' markieren" onConfirm={e => { markAsAccounted() }} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}

            <div className="d-flex justify-content-between">
                <div>
                    {!editName &&
                        <>
                            <input
                                checked={ExpenseBucket.currentSection == props.uuid ? true : false}
                                onClick={() => { dispatch(set_current_section(props.uuid)) }}
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1" /> &nbsp;
                            <span onClick={() => { setExpandView(!expandView) }} className="cursor-pointer">{ExpenseBucket.sections[props.uuid].name}</span>
                        </>
                    }

                    {editName &&
                        <>
                            <input className="form-control" type="text" value={ExpenseBucket.sections[props.uuid].name} onChange={(e) => { dispatch(rename_section(props.uuid, e.target.value)) }} />
                        </>
                    }
                </div>

                {Boolean(ExpenseBucket.currentSection == props.uuid && !editName) &&
                    <span className="text-primary cursor-pointer" onClick={() => { setEditName(true) }}>
                        Umbenennen
                    </span>
                }


                {Boolean(ExpenseBucket.currentSection == props.uuid && editName) &&
                    <span className="text-primary cursor-pointer mt-2 ms-1" onClick={() => { setEditName(false) }}>
                        Schließen
                    </span>
                }
            </div>

            {Boolean(ExpenseBucket.currentSection == props.uuid && !editName) &&
                <>
                    <table className={
                        stylelist(
                            "table table-light mb-0",
                            [ExpenseBucket.sections[props.uuid].expenses.length, "mt-2"]
                        )}>
                        {ExpenseBucket.sections[props.uuid].expenses.map(e => {
                            return <>
                                <tbody>
                                    <tr>
                                        <td className="small text-muted align-middle">{e.comment}</td>
                                        <td className="small text-muted align-middle"><MinutesToTime minutes={e.minutes} /></td>
                                        <td className="small text-muted align-middle">{e.date}</td>
                                        <td className="small text-muted align-middle"><span onClick={f => { dispatch(remove_entry(e.id)) }} className="text-primary small cursor-pointer">x</span></td>
                                    </tr>
                                </tbody>
                            </>
                        })}
                    </table>

                    {Boolean(ExpenseBucket.sections[props.uuid].expenses.length) &&
                        <>
                            <br />
                            <h6><span className="badge text-bg-secondary cursor-pointer" onClick={() => { setShowModal(true) }}>Exportieren</span></h6>
 
                        </>
                    }
                </>
            }

        </li>
    </>
}

export default ExpenseBucketSection;