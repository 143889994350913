import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useAuthentication = () => {
	// Connect to Redux-Slots
	const UserSettings = useSelector((state) => state.User);

	let returnObject = {
			getAuthObject : () => {
                return {
                    username: UserSettings.userName,
                    password: UserSettings.syncToken
                }
              }
		}
		return returnObject;
	}


export default useAuthentication