import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSlotName } from "../libs/SlotUtils";
import { combineReducers } from "redux";
import ical from 'ical-generator';
 
const useCalendar = () => {

    let returnObject = {
        createIcal: (title, date, slotNumber, ownerEmail = "info@unternehmen.online") => {

            const newiCal = ical({
                "prodId" : "//slotify.unternehmen.online//slot-reminder//DE"
            });

            if (slotNumber == 1) {
                date = moment(date).hours(8);
            }

            if (slotNumber == 2) {
                date = moment(date).hours(12);
            }

            const event = newiCal.createEvent({
                start: date,
                summary: "Erinnerung: " + title,
                location: "UO - True Digital Partners"
            });

            if (ownerEmail != "") {
                event.createAttendee({ email: ownerEmail });
            }


            return newiCal.toURL();
        },
    }

    return returnObject;
}

export default useCalendar