import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { set_sync_status } from "../../actions/Sync";
import { getSlotName } from "../../libs/SlotUtils";
import AddSlot from "../Slots/AddSlot";

const NG = (props) => {


    return (
        <>
            <div className="container mt-5">
                <div className="col-8">
                    <h2>Next-Gen-Funktionstests </h2>
                    <h4 className="mb-2 mb-4 mt-2 fw-light"><b>September 2024 &nbsp;</b>Aufwände und Buchungen (Abgeschlossen)</h4>
                    <p>Aufwände zu Slots und Projekten können direkt in Slotify erfasst und ausgewertet werden. <br /><br /> In Phase 1 (September 2024) werden primär Daten gesammelt und in einfachen Ansichten (Summen) angezeigt. Je nach Testergebnis und -erfahrung wird dies in den nächsten Monaten ausgebaut. Bitte nur verwenden, wenn vorher abgesprochen!
                    </p>
                </div>
            </div>
        </>
    );
};

export default NG;
