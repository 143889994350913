import React from "react";
import Gravatar from "../../libs/Gravatar";
import stylelist from "stylelist";

const TeamMemberInfoCard = (props) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row mt-3 mb-2">
                    <div className="offset-2 col-2 d-flex align-items-center justify-content-end">
                        <Gravatar className={stylelist("avatar")} email={props.TeamMember.email} />
                    </div>

                    <div className="col-7 d-flex align-items-center">
                        <div>
                            <h5 className="mb-1 pb-0 text-center">{props.TeamMember.realName}</h5>
                            <p className="mb-0 pb-0 text-center text-muted">{props.TeamMember.group}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TeamMemberInfoCard