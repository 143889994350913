import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_sync_status } from "../../actions/Sync";

const PersonLabelWithDynamicInfo = (props) => {
    const TeamSettings = useSelector((state) => state.Team);
    const UserSettings = useSelector((state) => state.User);
    const [displayedUser, setDisplayedUser] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {

        if(UserSettings.teamSynced == false){
            dispatch(set_sync_status("asap_full"));
        }
        
        setDisplayedUser(TeamSettings[props.name]);

    }, [TeamSettings]);

    return (
        <>
        {displayedUser != null && 
            <>
            {TeamSettings[props.name].realName}
            </>
        }

        {displayedUser == null && 
            <>
                <span className="text-muted">Lade Informationen für: {props.name}</span>
            </>
        }
        </>
    )
}

export default PersonLabelWithDynamicInfo