import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import SyncConstants from "../../constants/SyncConstants";
import { copy } from "../../libs/SlotUtils";
import PersonLabelWithDynamicInfo from "../Team/PersonLabelWithDynamicInfo";
import MinutesToTime from "./MinutesToTime";
import ConfirmButton from "./ConfirmButton";
import moment from "moment";
import stylelist from "stylelist";
import { Activities } from "../../constants/ExpenseConstants";
import { add_entry } from "../../actions/ExpenseBucket";
import ExpenseMask from "../Slots/ExpenseMask";
import useAuthentication from "../../hooks/useAuthentication";

const ExpenseEntryList = (props) => {

    const UserSettings = useSelector((state) => state.User);
    const ExpenseBucket = useSelector((state) => state.ExpenseBucket);
    const [serverData, setServerData] = useState({});
    const [showChildrenIds, setShowChildrenIds] = useState([]);
    const [showFullBreadcrumb, setShowFullBreadcrumb] = useState(false);
    const [showNewExpenseMask, setShowNewExpenseMask] = useState(false);
    const [showUpdateMask, setShowUpdateMask] = useState(false);
    const [updateEntry, setUpdateEntry] = useState({});
    const Auth = useAuthentication();
    const dispatch = useDispatch();

    // Beim Starten: Aktionen ausführen
    const init = useEffect(() => {
        loadFromServer();
    }, []);

    useEffect(() => {
        loadFromServer();
    }, [props.filterDateFrom, props.filterDateTo, props.filterPerson]);

    const addAllToBucket = () => {
        Object.values(serverData.entries).forEach(entry => {
            if (entry.accounted != 1) {
                dispatch(add_entry(entry));
            }
        });
    }

    const loadFromServer = () => {
        axios({
            method: "get",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntries/byProject",
            params: {
                project: props.project,
                filter: {
                    dateFrom: props.filterDateFrom,
                    dateTo: props.filterDateTo,
                    person: props.filterPerson
                }
            },
            responseType: 'json'
        }).then((response) => {
            setServerData(response.data);

        }).catch(response => { });
    }

    const deleteEntry = (entryId) => {
        axios({
            method: "delete",
            auth: Auth.getAuthObject(),
            url: SyncConstants["syncBaseURL"] + "expenseEntry",
            responseType: 'json',
            data: { entryId: entryId }
        }).then((response) => {
            loadFromServer();

        }).catch(response => { });
    }

    const afterEdit = () => {
        loadFromServer();
    }

    let currentDate;
    let isNewDate;
    return <>

        {serverData.entries !== undefined &&
            <>

                {showUpdateMask &&
                    <>
                        <div className="overlay d-flex justify-content-center align-items-center">
                            <div className="modal d-block">
                                <div className="modal-dialog" style={{ minWidth: "1024px" }}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Einen Aufwand bearbeiten</h5>
                                            <button type="button" className="btn-close" onClick={() => { setShowUpdateMask(false) }} aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <ExpenseMask update={true} updateEntry={updateEntry} afterSubmit={() => {setShowUpdateMask(false);setUpdateEntry({});loadFromServer();}} />
                                        </div>
                                        <div className="modal-footer">
                                            <span className="small text-muted">Projekte können aktuell aus Gründen der internen Zuordnung noch nicht bearbeitet werden.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <div className=" p-0">

                    {showNewExpenseMask &&
                        <>

                        </>
                    }

                    {Object.values(serverData.entries).length > 0 &&
                        <>
                            <table style={{ transition: "500ms" }} className={stylelist(
                                "table table-bordered mb-0 small table-hover",
                                [!props.separateByDays, "table-striped"]

                            )}>
                                <thead>
                                    <tr>

                                        <th className="text-center align-middle">Datum</th>
                                        <th className="align-middle">Person</th>
                                        {props.expandView && <th className="align-middle">Slot</th>}
                                        <th className="align-middle">Kommentar</th>
                                        <th className="text-center align-middle">Aufwand</th>
                                        <th className="align-middle">Tätigkeit u. Projekt
                                            &nbsp;
                                            {!showFullBreadcrumb &&
                                                <>
                                                    <i onClick={() => { setShowFullBreadcrumb(true); }} className="bi bi-info-circle cursor-pointer" title={"Ausklappen"}></i>
                                                </>
                                            }

                                            {showFullBreadcrumb &&
                                                <>
                                                    <i onClick={() => { setShowFullBreadcrumb(false); }} className="bi bi-x-circle cursor-pointer"></i>
                                                </>
                                            }

                                        </th>

                                        {!props.expenseBucket &&
                                            <>
                                                <th className="align-middle">Aktionen</th>
                                            </>
                                        }

                                        {props.expenseBucket &&
                                            <>
                                                <th onClick={() => { addAllToBucket() }} className="align-middle text-primary cursor-pointer text-center">Alle in Bucket verschieben</th>
                                            </>
                                        }

                                    </tr>
                                </thead>

                                <tbody>

                                    <>
                                        {currentDate = ""}
                                        {isNewDate = false}

                                        {Object.values(serverData.entries).sort((a, b) => { return b.date.toString().localeCompare(a.date) }).map(iteration => {

                                            if (currentDate != iteration.date) {
                                                isNewDate = true;
                                                currentDate = iteration.date;
                                            } else {
                                                isNewDate = false;
                                            }

                                            return <React.Fragment key={iteration.id}>

                                                {Boolean(isNewDate && props.separateByDays) &&
                                                    <>
                                                        <tr className="border-top-thick border-bottom-thick bg-tr-white">
                                                            <td colspan="7" className="text-center fw-bold pt-3 pb-3">
                                                                {moment(iteration.date).format("DD.MM.Y")}
                                                            </td>
                                                        </tr>
                                                    </>
                                                }

                                                <tr className={stylelist(
                                                    [isNewDate, ""],
                                                    [ExpenseBucket.addedIds.indexOf(iteration.id) > -1, "text-decoration-line-through"],
                                                    [iteration.accounted == 1, "opacity-25"]
                                                )}>

                                                    <td className=" align-middle text-center" style={{ height: "34px" }}>
                                                        {moment(iteration.date).format("DD.MM.Y")}
                                                    </td>

                                                    <td className="align-middle"><PersonLabelWithDynamicInfo name={iteration.person} /></td>

                                                    {props.expandView && <td className="align-middle">{iteration.slot_id}</td>}

                                                    <td className="align-middle">{iteration.comment}</td>

                                                    <td className="align-middle text-center"><MinutesToTime minutes={iteration.minutes} /></td>

                                                    <td className="align-middle " style={{ maxWidth: showFullBreadcrumb ? "650px" : "300px" }}>
                                                        {iteration.activity &&
                                                            <>{Activities[iteration.activity]}<br /></>
                                                        }
                                                        {!showFullBreadcrumb &&
                                                            <>
                                                                <span className="mt-1 extra-small-tabletext" >{(iteration.project_title).substr(0, 70)}</span>
                                                            </>
                                                        }

                                                        {showFullBreadcrumb &&
                                                            <>
                                                                <span className="mt-1 extra-small-tabletext" >{iteration.breadcrumb}</span>
                                                            </>
                                                        }
                                                    </td>

                                                    <td className="align-middle text-center">

                                                        {!props.expenseBucket &&
                                                            <>
                                                                <span className="text-primary small cursor-pointer border-bottom mb-1 pb-1 d-inline-block cursor-pointer" onClick={() => {setUpdateEntry(iteration);setShowUpdateMask(true)}}>Bearbeiten</span>

                                                                <ConfirmButton
                                                                    label="Löschen"
                                                                    labelConfirm="Wirklich?"
                                                                    className="text-primary cursor-pointer small"
                                                                    onConfirm={e => { deleteEntry(iteration.id) }} />
                                                            </>
                                                        }

                                                        {props.expenseBucket &&
                                                            <>
                                                                {Boolean(ExpenseBucket.addedIds.indexOf(iteration.id) < 0 && iteration.accounted != 1) &&
                                                                    <>
                                                                        <span className="text-primary  cursor-pointer" onClick={() => { dispatch(add_entry(iteration)) }}>Abrechnen</span>
                                                                    </>
                                                                }
                                                            </>
                                                        }

                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        })}
                                    </>

                                    <tr>
                                        <th className="fw-bold align-middle">Gesamt </th>

                                        <th></th>
                                        {props.expandView && <th></th>}
                                        <th></th>
                                        <th className="align-middle fw-bold text-center"><MinutesToTime minutes={serverData.calculation.total} /></th>
                                        <th></th>
                                        <th></th>
                                        {props.expandView && <th></th>}
                                    </tr>

                                </tbody>
                            </table>
                        </>
                    }

                    {Object.values(serverData.entries).length < 1 &&
                        <>
                            <p>Keine Buchungen mit den gewählten Filtern vorhanden.</p>
                        </>
                    }
                </div>
            </>
        }
    </>
}

export default ExpenseEntryList;