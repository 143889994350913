import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';

const Gravatar = ({
  email,
  md5: customHash,
  size = 50,
  rating = 'g',
  default: defaultImage = 'retro',
  protocol = '//',
  domain = 'www.gravatar.com',
  className = '',
  style = {},
  alt = '',
  ...rest
}) => {
  // Erstelle den Hash basierend auf der E-Mail oder der mitgegebenen md5-Prop
  const hash = customHash || (email ? md5(email.trim().toLowerCase()) : null);

  if (!hash) {
    console.warn(
      'Gravatar image cannot be fetched. Either the "email" or "md5" prop must be specified.'
    );
    return null;
  }

  // Basis-URL für den Gravatar-Service
  const base = `${protocol}${domain}/avatar/${hash}`;

  // Manuelles Erstellen der Query-Strings
  const query = `s=${size}&r=${rating}&d=${defaultImage}`;
  const retinaQuery = `s=${size * 2}&r=${rating}&d=${defaultImage}`;

  return (
    <img
      src={`${base}?${query}`}
      srcSet={`${base}?${retinaQuery} 2x`}
      width={size}
      height={size}
      style={style}
      className={`react-gravatar ${className}`}
      alt={alt}
      {...rest}
    />
  );
};

Gravatar.propTypes = {
  email: PropTypes.string,
  md5: PropTypes.string,
  size: PropTypes.number,
  rating: PropTypes.string,
  default: PropTypes.string,
  protocol: PropTypes.string,
  domain: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
};

export default Gravatar;